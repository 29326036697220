// Tailwind CSS
@tailwind base;
@tailwind components;
@tailwind utilities;

// Google Fonts
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+TC:wght@500;700;900&display=swap');

// Body
body {
  @apply bg-white overflow-x-hidden font-common text-gray-900 text-[16px] leading-[30px] cursor-default;
}

// Expand
* {
  -webkit-tap-highlight-color: transparent;
  &:focus {
    @apply outline-none;
  }
}
input, textarea {
  @apply cursor-pointer;
}
textarea {
  @apply resize-none;
}
img {
  @apply select-none;
}
.horizontal-tb {
  writing-mode: horizontal-tb;
}
.vertical-rl {
  writing-mode: vertical-rl;
}
.vertical-lr {
  writing-mode: vertical-lr;
}
.scrolling-touch {
  -webkit-overflow-scrolling: touch;
}

// Index
.indexLogo {
  background-image: url('~/assets/index_logo.svg');
  background-size: 100% auto;
  @apply bg-transparent bg-center bg-no-repeat;
}
.slick-slider {
  @apply h-full;
  .slick-list {
    @apply h-full;
    .slick-track {
      @apply h-full;
      .slick-slide {
        @apply h-full;
        >div {
          @apply h-full;
        }
      }
    }
  }
}

// Header
.pageLogo {
  background-image: url('~/assets/page_logo.svg');
  background-size: auto 100%;
  @apply bg-transparent bg-center bg-no-repeat;
}

// About
.bannerAbout {
  background-image: url('~/assets/banner_about.jpg');
  @apply bg-gray-100 bg-center bg-cover bg-no-repeat;
}

// Contact
.bannerContact {
  background-image: url('~/assets/banner_contact.jpg');
  @apply bg-gray-100 bg-center bg-cover bg-no-repeat;
}
